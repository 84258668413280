const React = require("react")
const Layout = require("./src/components/layout/Layout").default
const { ContextsProvider } = require("./src/components/utilities/SiteContexts")


exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

exports.wrapRootElement = ({ element }) => {
  return <ContextsProvider>{element}</ContextsProvider>
}