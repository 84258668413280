import React, {useState, useEffect }from "react"

import "../../styles/reset.css"
import "../../styles/styles.scss"

import { mainHolder, bgImageHolder, headerHolder } from "./Layout.module.scss"

import useWindowSize from '../../hooks/useWindowSize'

import {Logo, SmallLogo} from "./Logo"
import Nav from "./Nav"
// import TourTicketsCircle from '../components/TourTicketsCircle'
import SocialNav from "./SocialNav"
import Footer from "./Footer"
import { StaticImage } from "gatsby-plugin-image"
import { motion, AnimatePresence } from 'framer-motion'


const Layout = ({ children, location, ...props}) => {
  const [isHome, setIsHome] = useState(children?.props?.path==='/')
  const [isDark, setIsDark] = useState(children?.props?.location.pathname.startsWith('/livestream'))
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const size = useWindowSize()


  const mainVariants = {
    pre: {
      y:20,
      opacity:0
    },
    visible: {
      y:0,
      opacity:1,
      transition: {
        when: "beforeChildren",
        type: "tween",
        duration: 0.75
      }
    },
    post: {
      opacity:0,
      y:-20,
      transition: {
        when: "afterChildren",
        type: "tween",
        duration: 0.3
      }
    }
  }

  const bgVariants = {
    home: {
      scale:1,
      x: 0,
      y: 0,
      opacity:1,
      transition: {
        type: 'tween',
        duration: 1.5
      }
    },
    notHome: {
      scale: 1.7,
      x:'-40%',
      y:'20%',
      opacity:0.2,
      transition: {
        type: 'tween',
        duration: 1.5
      }
    },
    isDark: {
      scale: 1.7,
      x:'-40%',
      y:'20%',
      opacity:0,
      transition: {
        type: 'tween',
        duration: 1.5
      }
    }
  }


  useEffect(()=>{
    const breakpoint = 700

    if(size.width < breakpoint)
    setIsSmallScreen(true)

    if(size.width > breakpoint)
    setIsSmallScreen(false)

    if(((size.width / size.height) > 1.7) && (size.height < 600))
    setIsSmallScreen(true)

  }, [size, setIsSmallScreen])



  useEffect(()=> {
    setIsHome(children?.props?.path==='/')

    setIsDark(children?.props?.location.pathname.startsWith('/livestream'))
  }, [children, setIsHome, setIsDark])








  return (
    <div className={mainHolder} style={ isDark ? { '--bg' : '#1E1C1B', '--textColor': '#fff'} : { '--bg' : '#F1EBE5', '--textColor': '#000'} } >

      <header className={headerHolder}>
          <Logo isHome={isHome} />
          <SmallLogo isHome={isHome} />
          <Nav isSmallScreen={isSmallScreen} />
          <SocialNav />
          {/* <TourTicketsCircle /> */}
      </header>

      <AnimatePresence exitBeforeEnter>
        <motion.main key={children?.props?.path} variants={mainVariants} initial="pre" animate="visible" exit="post">
          {children}
        </motion.main>
      </AnimatePresence>

      <Footer />

      <motion.div className={bgImageHolder} variants={bgVariants} animate={isHome ? 'home' : ( isDark ? 'isDark' : 'notHome' )} initial={false}>
          <motion.div className="bgImageHolderInner">
            <StaticImage
              src="../../images/The Woman You Want - website artwork bg.jpg"
              alt="Eliza Shaddad - The Woman You Want"
              quality={80}
              width={1300}
            />
          </motion.div>
      </motion.div>

    </div>
  )
}

export default Layout
