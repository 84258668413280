import React from 'react'
import {footerHolder} from './Footer.module.scss'

export default function Footer() {
  return (
    <footer className={footerHolder}>
    Copyright 2021 Eliza Shaddad | Website by <a href="https://www.mrbjjackson.com/">Mr BJ Jackson</a>
    </footer>
  )
}
