import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faSpotify, faApple, faYoutube , faTwitter} from '@fortawesome/free-brands-svg-icons'

import { socialNavHolder } from './SocialNav.module.scss'

export default function SocialNav() {
  return (
    <nav className={socialNavHolder}>
      <ul>
        <li><a href="https://www.instagram.com/elizashaddad/" title="Instagram" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></li>
        <li><a href="https://www.facebook.com/elizashaddad" title="Facebook" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></li>
        <li><a href="https://www.twitter.com/elizashaddad" title="Twitter" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></li>
        <li><a href="https://open.spotify.com/artist/3B63aIfra43Vs4F33ecM9E?si=5-a_8A5MT3-wyLimEzrr3A" title="Spotify" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faSpotify} /></a></li>
        <li><a href="https://music.apple.com/us/artist/eliza-shaddad/733120559" title="Apple Music" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faApple} /></a></li>
        <li><a href="https://www.youtube.com/channel/UCWeZiMsnW39ldPlmQcdWGDw" title="Youtube" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a></li>
      </ul>
    </nav>
  )
}
