import React from 'react'
import { logoHolder, smallLogoHolder } from './Layout.module.scss'
import {AnimatePresence, motion} from 'framer-motion'
import { Link } from 'gatsby'
import  E  from '../../images/logo/letters/E.svg'
import  L  from '../../images/logo/letters/L.svg'
import  I  from '../../images/logo/letters/I.svg'
import  Z  from '../../images/logo/letters/Z.svg'
import  A  from '../../images/logo/letters/A.svg'
import  S  from '../../images/logo/letters/S.svg'
import  H  from '../../images/logo/letters/H.svg'
import  D  from '../../images/logo/letters/D.svg'

export function Logo({isHome}) {

  const letterHolderVariants = {
    pre: {
      opacity:1
    },
    visible: {
      opacity:1,
      transition: {
        type: 'tween',
        staggerChildren:0.1
      }
    },
    post: {
      opacity:1,
      transition: {
        type: 'tween',
        staggerChildren:0.06,
        staggerDirection: -1
      }
    }
  }

  const letterVariants = {
    pre: {
      opacity:0,
      rotateX:45,
      scale:0.9
    },
    visible: {
      opacity:1,
      rotateX:0,
      scale:1,
      transition: {
        type:'tween',
        duration:1,
      }
    },
    post: {
      opacity:0,
      rotateX:45,
      scale:0.9,
      transition: {
        type:'tween',
        duration:0.1,
      }
    }
  }

  return (
    <motion.div className={logoHolder} initial={false} >
      <Link to="/" title="Eliza Shadddad - Home" className="logoHolderInner">
        <AnimatePresence>
        { isHome && <motion.ul variants={letterHolderVariants} initial="pre" animate="visible" exit="post">
          <motion.li key="E1" variants={letterVariants}><E /></motion.li>
          <motion.li key="L1"  variants={letterVariants}><L /></motion.li>
          <motion.li  key="I1" variants={letterVariants}><I /></motion.li>
          <motion.li  key="Z1" variants={letterVariants}><Z /></motion.li>
          <motion.li  key="A1" variants={letterVariants}><A /></motion.li>
          <motion.li  key="A2" variants={letterVariants} className="spacer"></motion.li>
          <motion.li  key="S1" variants={letterVariants}><S /></motion.li>
          <motion.li  key="H1" variants={letterVariants}><H /></motion.li>
          <motion.li  key="A3" variants={letterVariants}><A /></motion.li>
          <motion.li  key="D1" variants={letterVariants}><D /></motion.li>
          <motion.li  key="D2" variants={letterVariants}><D /></motion.li>
          <motion.li  key="A4" variants={letterVariants}><A /></motion.li>
          <motion.li  key="D3" variants={letterVariants}><D /></motion.li>
        </motion.ul> }
      </AnimatePresence>
      </Link>
    </motion.div>
  )
}


export function SmallLogo({isHome}) {

  const letterHolderVariants = {
    pre: {
      opacity:1
    },
    visible: {
      opacity:1,
      transition: {
        type: 'tween',
        staggerChildren:0.1
      }
    },
    post: {
      opacity:1,
      transition: {
        type: 'tween',
        staggerChildren:0.06,
        staggerDirection: -1
      }
    }
  }

  const letterVariants = {
    pre: {
      opacity:0,
      rotateX:45,
      scale:0.9
    },
    visible: {
      opacity:1,
      rotateX:0,
      scale:1,
      transition: {
        type:'tween',
        duration:1,
      }
    },
    post: {
      opacity:0,
      rotateX:45,
      scale:0.9,
      transition: {
        type:'tween',
        duration:0.1,
      }
    }
  }

  return (
    <motion.div className={smallLogoHolder} initial={false} >
      <Link to="/" title="Eliza Shadddad - Home" className="logoHolderInner">
        <h1>Eliza Shaddad</h1>
        <AnimatePresence>
        { !isHome && <motion.ul variants={letterHolderVariants} initial="pre" animate="visible" exit="post">
          <motion.li key="E1" variants={letterVariants}><E /></motion.li>
          <motion.li key="L1"  variants={letterVariants}><L /></motion.li>
          <motion.li  key="I1" variants={letterVariants}><I /></motion.li>
          <motion.li  key="Z1" variants={letterVariants}><Z /></motion.li>
          <motion.li  key="A1" variants={letterVariants}><A /></motion.li>
          <motion.li  key="A2" variants={letterVariants} className="spacer"></motion.li>
          <motion.li  key="S1" variants={letterVariants}><S /></motion.li>
          <motion.li  key="H1" variants={letterVariants}><H /></motion.li>
          <motion.li  key="A3" variants={letterVariants}><A /></motion.li>
          <motion.li  key="D1" variants={letterVariants}><D /></motion.li>
          <motion.li  key="D2" variants={letterVariants}><D /></motion.li>
          <motion.li  key="A4" variants={letterVariants}><A /></motion.li>
          <motion.li  key="D3" variants={letterVariants}><D /></motion.li>
        </motion.ul> }
      </AnimatePresence>
      </Link>
    </motion.div>
  )
}